import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import { cn } from "../utils";
import { UIUtils } from "../utils/UIUtils";
import { Stepper, StepperConfig } from "./Stepper";
import { Container } from "./Container";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { BuilderUtils } from "../utils/BuilderUtils";
import { Quote } from "../types/Quote.interface";
import { FieldValues, UseFormSetValue } from "react-hook-form";

interface SiteHeaderProps<StepIdType extends string> {
    quote?: Quote;
    updateQuote?: UseFormSetValue<Quote>;
    underwriter: PetUnderwriterType;
    formID: string;
    phoneNumber?: string;
    phoneNumberDisplay?: string;
    logo?: JSX.Element;
    logoClass?: string;
    currentStep?: StepIdType;
    steps?: StepperConfig<StepIdType>[];
    updateCurrentStep?: (stepId: StepIdType, quoteId?: string) => void;
}

const SiteHeader = <StepIdType extends string>({
    quote,
    updateQuote,
    phoneNumber,
    logo,
    logoClass,
    currentStep,
    steps,
    updateCurrentStep,
    formID,
    underwriter
}: SiteHeaderProps<StepIdType>) => {
    const formattedPhone = phoneNumber ? UIUtils.formatPhone(phoneNumber) : "";
    const builderUtils = new BuilderUtils(underwriter);

    const handleUpdateData = async (dataArray: { type: keyof (typeof BuilderUtils)["typeToFieldKeyMap"]; data: FieldValues }[]) => {
        await builderUtils.updateQuoteExtraData({ quote, newDataArray: dataArray, updateQuote });
    };

    return (
        <React.Fragment>
            {/* Preheader */}
            {formattedPhone && (
                <div className="bg-background-secondary px-4 py-1 sm:px-6 lg:px-10 xl:p-0">
                    <CustomizationSlot
                        type="announcement-bar"
                        data={quote}
                        formId={formID}
                        formStepId={currentStep}
                        formData={quote?.extra?.formData}
                        updateData={handleUpdateData}
                    />
                    <div className="mx-auto w-full max-w-content">
                        <a href={`tel:${phoneNumber}`} className="text-xs text-content-primary underline-offset-4">
                            <FontAwesomeIcon icon={faPhone} size="sm" className="mr-1" />
                            {formattedPhone}
                        </a>
                    </div>
                </div>
            )}
            {/* Header */}
            <Container>
                <div className="flex flex-row flex-wrap items-center gap-4 py-3">
                    {logo && <div className={cn("w-[136px]", logoClass)}>{logo}</div>}
                    <div className="flex-1">
                        <CustomizationSlot type="co-brand" data={quote} formId={formID} formStepId={currentStep} />
                    </div>
                </div>
                {!!steps && currentStep && !!updateCurrentStep && <Stepper currentStep={currentStep} steps={steps} updateCurrentStep={updateCurrentStep} />}
                <CustomizationSlot type="form-header" data={quote} formId={formID} formStepId={currentStep} formData={quote?.extra?.formData} updateData={handleUpdateData} />
                <CustomizationSlot
                    type="modal"
                    data={quote}
                    formId={formID}
                    formStepId={currentStep}
                    formData={quote?.extra?.formData}
                    modalData={quote?.extra?.modalStates}
                    updateData={handleUpdateData}
                />
            </Container>
        </React.Fragment>
    );
};

export default SiteHeader;
